import {
    Token,
    GetOverwolfXpSubjectEnum,
    OverwolfApi,
    GetOverwolfXPResponse,
} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {keepPreviousData, useQuery} from "@tanstack/react-query";


async function fetchUserOverWolfXP(fullToken: Token, subject: GetOverwolfXpSubjectEnum, overwolfRewardId?: string, notes?: string): Promise<GetOverwolfXPResponse> {
    return await new OverwolfApi(getConfig(fullToken)).getOverwolfXp({
        subject,
        overwolfRewardId,
        notes,
    });
}

function useUserOverWolfXPQuery(fullToken: Token, subject: GetOverwolfXpSubjectEnum, overwolfRewardId?: string, notes?: string) {
    return {
        queryKey: [
            "user-overwolf-xp",
            fullToken?.id ?? "unauthenticated",
            subject,
            overwolfRewardId ?? "",
            notes ?? "",
        ],
        queryFn: () => fullToken && subject ? fetchUserOverWolfXP(fullToken, subject, overwolfRewardId, notes) : null,
        staleTime: 30 * 60000,
        placeholderData: keepPreviousData,
        enabled: !!fullToken && !!subject,
    } as const;
}

export default function useGetUserOverwolfXP(fullToken: Token, subject: GetOverwolfXpSubjectEnum, overwolfRewardId?: string, notes?: string) {
    return useQuery(useUserOverWolfXPQuery(fullToken, subject, overwolfRewardId, notes));
}
